<template>
  <b-table
    :fields="fields"
    :items="previousMentorships"
    responsive
  >
    <template #head()="{label}">
      {{ label | upper }}
    </template>
    <template #cell(mentorship)="{item}">
      {{ $t(item.package_name) }}
    </template>
    <template #cell(mentee)="{item}">
      <b-card-text
        class="cursor-pointer"
        style="color: #EC6730;"
        @click="$emit('show-mentee-details', item)"
      >
        {{ item.mentee.user.name }} {{ item.mentee.user.surname }}
      </b-card-text>
    </template>
    <template #cell(duration)="{item}">
      <div class="mentorship-duration-info">
        {{ item.started_at_date | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit' }) }} -
        {{ item.ended_at_date | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit' }) }}
      </div>
    </template>
    <template
      v-if="$can('read', 'mentor')"
      #cell(rate-mentorship)=""
    >
      <b-button
        variant="primary"
        :href="rateMentorshipUrls.mentorUrl"
        target="_blank"
      >
        {{ $t('Rate mentorship') }}
      </b-button>
    </template>
  </b-table>
</template>

<script>
import {
  BButton,
  BTable,
  BCardText,
} from 'bootstrap-vue'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'
import { rateMentorshipUrls } from '@mentoring-platform/constants'

export default {
  components: {
    BButton,
    BTable,
    BCardText,
  },
  filters: {
    upper,
    formatGreekDate,
  },
  props: {
    previousMentorships: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rateMentorshipUrls,
    }
  },
  computed: {
    fields() {
      const fields = [{
        key: 'mentorship',
        label: this.$t('Mentorship'),
        tdClass: 'font-weight-bolder',
      }, {
        key: 'mentee',
        label: 'Mentee',
      }, {
        key: 'duration',
        label: this.$t('Duration'),
      }]
      if (this.$can('read', 'mentor')) {
        fields.push({
          key: 'rate-mentorship',
          label: this.$t('Testimonial'),
        })
      }
      return fields
    },
  },
}
</script>

<style lang="scss" scoped>
.mentorship-duration-info {
  min-width: 200px;
}
</style>
