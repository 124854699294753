var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"mentorProfileObserver"},[(_vm.isMentee)?_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"i-name"}},[_vm._v(" "+_vm._s(_vm.$t('Name'))+" "),_c('required-field')],1),_vm._l((_vm.selectedLocales),function(locale,index){return _c('div',{key:index,class:index < _vm.selectedLocales.length - 1 ? 'mb-1' : ''},[_c('validation-provider',{attrs:{"name":_vm.name[locale],"rules":"required|min:2|max:50","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('localized-input',{attrs:{"id":'i-name' + index,"size":"lg","locale":locale,"append-flag":_vm.selectedLocales.length > 1},model:{value:(_vm.name[locale]),callback:function ($$v) {_vm.$set(_vm.name, locale, $$v)},expression:"name[locale]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)})],2)],1),_c('b-col',{attrs:{"xs":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-surname"}},[_vm._v(" "+_vm._s(_vm.$t('Surname'))+" "),_c('required-field')],1),_vm._l((_vm.selectedLocales),function(locale,index){return _c('div',{key:index,class:index < _vm.selectedLocales.length - 1 ? 'mb-1' : ''},[_c('validation-provider',{attrs:{"name":_vm.surname[locale],"rules":"required|min:3|max:50","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('localized-input',{attrs:{"id":'i-surname' + index,"size":"lg","locale":locale,"append-flag":_vm.selectedLocales.length > 1},model:{value:(_vm.surname[locale]),callback:function ($$v) {_vm.$set(_vm.surname, locale, $$v)},expression:"surname[locale]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)})],2)],1)],1):_vm._e(),(_vm.isMentee)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-professional-title"}},[_vm._v(" "+_vm._s(_vm.$t('ProfessionalTitle'))+" "),_c('required-field')],1),_vm._l((_vm.selectedLocales),function(locale,index){return _c('div',{key:index,class:index < _vm.selectedLocales.length - 1 ? 'mb-1' : ''},[_c('validation-provider',{attrs:{"name":_vm.professionalTitle[locale],"rules":"required|max:200","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('localized-input',{attrs:{"id":'i-professional-title' + index,"size":"lg","locale":locale,"append-flag":_vm.selectedLocales.length > 1},model:{value:(_vm.professionalTitle[locale]),callback:function ($$v) {_vm.$set(_vm.professionalTitle, locale, $$v)},expression:"professionalTitle[locale]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)})],2)],1)],1):_vm._e(),(_vm.mentor)?_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-card-title',{staticClass:"card-title font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.getMentorVisibleFullName(_vm.mentor))+" ")]),(_vm.mentor.is_mentor && _vm.mentor.is_coach)?_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('Mentor'))+" / "+_vm._s(_vm.$t('Coach'))+" ")]):(_vm.mentor.is_mentor)?_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('Mentor'))+" ")]):_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('Coach'))+" ")])],1)],1):_vm._e(),(_vm.mentor)?_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-link',[_c('b-img',{ref:"previewEl",staticClass:"grayscale-img",attrs:{"rounded":"","src":_vm.photoAsBase64,"height":"80"}})],1)],1),_c('b-media-body',{staticClass:"mt-75 ml-75"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.refInputEl.$el.click()}}},[_vm._v(" "+_vm._s(_vm.$t('Upload'))+" ")]),_c('validation-provider',{ref:"photo-validator",attrs:{"name":"photo","rules":"size:1024|min_dimensions:300,300","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",attrs:{"accept":"image/jpeg, image/png","hidden":true,"plain":"","size":"lg"},on:{"change":_vm.newPhotoSelected},model:{value:(_vm.photo),callback:function ($$v) {_vm.photo=$$v},expression:"photo"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Reset'))+" ")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('Allowed JPG or PNG. Max size of 1024kB'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3672454130)})],1)],1)],1)],1):(!_vm.showOnlyMultilanguageFields)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"description":_vm.$t('The photo will appear in your profile in the list of mentors')}},[_c('validation-provider',{ref:"photo-validator",attrs:{"name":"photo","rules":"required|size:1024|min_dimensions:300,300","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"i-photo"}},[_vm._v(_vm._s(_vm.$t('Photo'))+" ("+_vm._s(_vm.$t('Photo specs'))+") "),_c('required-field')],1),_c('b-form-file',{attrs:{"id":"i-photo","placeholder":"JPG, PNG","accept":"image/jpeg, image/png"},on:{"change":_vm.newPhotoSelected},model:{value:(_vm.photo),callback:function ($$v) {_vm.photo=$$v},expression:"photo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"i-mentor-description"}},[_vm._v(" "+_vm._s(_vm.$t('Mentor description'))+" "),_c('required-field')],1),_c('b-form-text',[_vm._v(" "+_vm._s(_vm.$t('Mentor description more'))+" ")]),_vm._l((_vm.selectedLocales),function(locale,index){return _c('div',{key:index,class:index < _vm.selectedLocales.length - 1 ? 'mb-1' : ''},[_c('validation-provider',{attrs:{"name":_vm.mentorDescription[locale],"rules":"required|max:1400","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('localized-textarea',{attrs:{"id":'i-mentor-description' + index,"locale":locale,"append-flag":_vm.selectedLocales.length > 1 || _vm.forceFlags,"max-chars":1400},model:{value:(_vm.mentorDescription[locale]),callback:function ($$v) {_vm.$set(_vm.mentorDescription, locale, $$v)},expression:"mentorDescription[locale]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)})],2)],1)],1),(!_vm.showOnlyMultilanguageFields)?_c('b-row',[_c('b-col',[_c('label',[_vm._v(" "+_vm._s(_vm.$t('With which link do you want your profile related to?'))+" ")]),_c('b-form-text',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t('Mentor links explanation'))+" ")])],1)],1):_vm._e(),_vm._l((_vm.mentorLinks),function(linkData,index){return [(linkData.visible && !_vm.showOnlyMultilanguageFields)?_c('b-row',{key:index},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":'mentorLink' + index,"rules":"required|url","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"type":"text","placeholder":"https://","size":"lg"},model:{value:(_vm.mentorLinks[index].url),callback:function ($$v) {_vm.$set(_vm.mentorLinks[index], "url", $$v)},expression:"mentorLinks[index].url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),(index === _vm.visibleMentorLinksCount - 1)?_c('b-col',{staticClass:"d-flex align-items-start"},[(_vm.canRemoveLink)?_c('b-button',{staticClass:"mb-1",attrs:{"variant":"outline-danger"},on:{"click":_vm.removeLink}},[_c('i',{staticClass:"feather icon-x"}),_vm._v(_vm._s(_vm.$t('Remove'))+" ")]):_vm._e()],1):_vm._e()],1):_vm._e()]}),(!_vm.showOnlyMultilanguageFields)?_c('b-row',[_c('b-col',[(_vm.canAddLink)?_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.addLink}},[_c('i',{staticClass:"feather icon-plus"}),_vm._v(_vm._s(_vm.$t('Add'))+" ")]):_vm._e()],1)],1):_vm._e(),(_vm.isMentee)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-form-checkbox',{attrs:{"value":"1"},model:{value:(_vm.giveConsent),callback:function ($$v) {_vm.giveConsent=$$v},expression:"giveConsent"}},[_vm._v(" "+_vm._s(_vm.$t('MentorGiveConsent'))+" ")])],1)],1):_vm._e(),(_vm.isMentee)?_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"acceptTermsAndConditions","rules":{
            required: {
              allowFalse: false
            }
          },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"value":"1"},model:{value:(_vm.acceptTermsAndConditions),callback:function ($$v) {_vm.acceptTermsAndConditions=$$v},expression:"acceptTermsAndConditions"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('I agree to'))+" "),_c('b-link',{attrs:{"to":{ name: 'mentoring-terms' },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('the terms and conditions of WHEN'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('and'))+" "),_c('b-link',{attrs:{"to":{ name: 'privacy-policy' },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('the privacy policy'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('as a mentor'))+" "),_c('required-field')],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,3819978344)})],1)],1):_vm._e()],2),_c('image-cropper-modal',{ref:"image-cropper-modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }