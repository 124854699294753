<template>
  <div>
    <span
      id="mentorship-duration"
      class="font-weight-bolder text-primary"
    >
      {{ from | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit' }) }} - {{ to | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit' }) }}
    </span>
  </div>
</template>

<script>
import { formatGreekDate } from '@mentoring-platform/utils/filters'

export default {
  components: {

  },
  filters: {
    formatGreekDate,
  },
  props: {
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
#mentorship-duration {
  background: #8044701a;
  padding: 5px;
  font-size: 14px;
}
</style>
