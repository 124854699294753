var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticClass:"desktop-only",attrs:{"fields":_vm.fields,"items":_vm.mentorshipRequests,"responsive":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm._f("upper")(label))+" ")]}},{key:"cell(package_name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.package_name))+" ")]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatGreekDate")(item.created_at))+" ")]}},{key:"cell(mentee)",fn:function(ref){
var item = ref.item;
return [_c('b-card-text',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.$emit('show-mentee-details', item)}}},[_vm._v(" "+_vm._s(item.mentee.user.name)+" "+_vm._s(item.mentee.user.surname)+" ")])]}},{key:"cell(view_request)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('show-mentee-details', item)}}},[_vm._v(" "+_vm._s(_vm.$t('View mentorship request'))+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"mentorship-request-actions"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$emit('reject-request', data.item)}}},[_vm._v(" "+_vm._s(_vm.$t('Rejection'))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('approve-request', data.item)}}},[_vm._v(" "+_vm._s(_vm.$t('Approval'))+" ")])],1)]}}])}),_vm._l((_vm.mentorshipRequests),function(request,id){return _c('mentorship-requests-card',{key:("mentorship-request-" + id),staticClass:"mobile-only",attrs:{"request":request},on:{"show-mentee-details":function($event){return _vm.$emit('show-mentee-details', request)},"reject-request":function($event){return _vm.$emit('reject-request', request)},"approve-request":function($event){return _vm.$emit('approve-request', request)}}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }