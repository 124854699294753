<template>
  <b-card
    class="mobile-only"
  >
    <b-card-text
      v-for="field in fields"
      :key="field.label"
    >
      <div :key="field.label">
        <b-row
          v-if="field.label !== ''"
          class="card-row card-row-label"
        >
          {{ field.label }}
        </b-row>
        <b-row class="card-row">
          <template v-if="field.key === 'package_name'">
            {{ request.package_name }}
          </template>
          <template v-else-if="field.key === 'created_at'">
            {{ request.created_at | formatGreekDate }}
          </template>
          <template v-else-if="field.key === 'mentee'">
            <b-card-text
              class="text-primary cursor-pointer"
              @click="$emit('show-mentee-details', request)"
            >
              {{ request.mentee.user.name }} {{ request.mentee.user.surname }}
            </b-card-text>
          </template>
          <template v-else-if="field.key === 'view_request'">
            <b-button
              variant="primary"
              class="mr-1"
              @click="$emit('show-mentee-details', request)"
            >
              {{ $t('View mentorship request') }}
            </b-button>
          </template>
          <template v-else-if="field.key === 'actions'">
            <div class="mentorship-request-actions">
              <b-button
                variant="outline-primary"
                class="mr-1"
                @click="$emit('reject-request', request)"
              >
                {{ $t('Rejection') }}
              </b-button>
              <b-button
                variant="primary"
                @click="$emit('approve-request', request)"
              >
                {{ $t('Approval') }}
              </b-button>
            </div>
          </template>
          <template v-else />
        </b-row>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BRow,
} from 'bootstrap-vue'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BRow,
  },
  filters: {
    formatGreekDate,
    upper,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    fields() {
      return [{
        key: 'package_name',
        label: this.$t('Package'),
      }, {
        key: 'created_at',
        label: this.$t('Request date'),
      }, {
        key: 'mentee',
        label: this.$t('Mentee'),
      }, {
        key: 'view_request',
        label: '',
      }, {
        key: 'actions',
        label: this.$t('Actions'),
      }]
    },
  },
}
</script>

<style lang="scss" scoped>
.mentorship-request-actions {
  min-width: 248px;
}

.mobile-only {
  @media (min-width: 575px){
    display: none;
  }
}

.card-row {
  padding: 16px 32px;
  margin-right: 0;
  margin-left: 0;
  border-bottom: 2px solid #ebe9f1;
  &-label {
    background-color: #f3f2f7
  }
}

.card-body {
  padding: 0 !important;
}

.card-text {
  margin: 0 !important;
}
</style>
