import { extend, localize } from 'vee-validate'
import {
  max as rule_max,
  size as rule_size,
  required_if as rule_required_if,
} from 'vee-validate/dist/rules'
import {
  validatorPassword,
  validatorUrlValidator,
  validatorImageDimensions,
  validatorDateFormat,
  validatorWorkingExperience,
} from './validators'

extend('max', rule_max)

extend('size', rule_size)

extend('required_if', rule_required_if)

extend('password', {
  validate: validatorPassword,
  message: null, // do not remove because overrides src/@core/utils/validations/validations.js
})

extend('url', {
  validate: validatorUrlValidator,
  message: null, // do not remove because overrides src/@core/utils/validations/validations.js
})

extend('min_dimensions', {
  validate: validatorImageDimensions,
  params: [
    {
      name: 'width',
    },
    {
      name: 'height',
    },
  ],
  message: null,
})

extend('date_format', {
  validate: validatorDateFormat,
  params: [
    {
      name: 'format',
    },
  ],
  message: null,
})

extend('working_experience', {
  validate: validatorWorkingExperience,
  message: null,
})

const dictionary = {
  gr: {
    messages: {
      required: 'Το πεδίο είναι υποχρεωτικό',
      required_if: 'Το πεδίο είναι υποχρεωτικό',
      email: 'Το email πρέπει να είναι έγκυρο',
      password: 'Ο κωδικός πρέπει να περιλαμβάνει τουλάχιστον έναν αριθμό, έναν ειδικό χαρακτήρα και να αποτελείται από 8 ή περισσότερους λατινικούς χαρακτήρες.',
      min: 'Το πεδίο πρέπει να αποτελείται από τουλάχιστον {length} χαρακτήρες',
      max: 'Το πεδίο δεν πρέπει να υπερβαίνει τους {length} χαρακτήρες',
      size: 'Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα {size}KB',
      date_format: (field, { format }) => `Παρακαλούμε συμπλήρωσε την ημερομηνία με τη μορφή ${format.replace(/Y/ig, 'Ε').replace(/D/ig, 'Η').replace(/M/ig, 'Μ')}`,
      min_dimensions: 'Η ελάχιστη ανάλυση της φωτογραφίας πρέπει να είναι {width}x{height} pixel.',
      url: 'Το link πρέπει να ξεκινάει με http:// ή https:// και να αποτελεί έγκυρο URL',
      working_experience: 'Η ημερομηνία πρέπει να είναι από 01-1970 και μετά',
    },
  },
  en: {
    messages: {
      required: 'The field is required',
      required_if: 'The field is required',
      email: 'The field must be a valid email',
      password: 'Your password must contain at least one number, one special character and be at least 8 latin characters.',
      min: 'The field must be at least {length} characters',
      max: 'The field may not be greater than {length} characters',
      size: 'The file size must be less than {size}KB',
      date_format: (field, { format }) => `Please fill in the date in the format ${format.replace(/Y/ig, 'Y').replace(/D/ig, 'D').replace(/M/ig, 'M')}`,
      min_dimensions: 'The minimum resolution of the photo must be {width}x{height} pixel.',
      url: 'The link must start with http:// or https:// and be a valid URL',
      working_experience: 'The date should be from 01-1970 onwards',
    },
  },
}

localize(dictionary)

export * from '@validations'
