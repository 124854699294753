var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.previousMentorships,"responsive":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm._f("upper")(label))+" ")]}},{key:"cell(mentorship)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.package_name))+" ")]}},{key:"cell(mentee)",fn:function(ref){
var item = ref.item;
return [_c('b-card-text',{staticClass:"cursor-pointer",staticStyle:{"color":"#EC6730"},on:{"click":function($event){return _vm.$emit('show-mentee-details', item)}}},[_vm._v(" "+_vm._s(item.mentee.user.name)+" "+_vm._s(item.mentee.user.surname)+" ")])]}},{key:"cell(duration)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mentorship-duration-info"},[_vm._v(" "+_vm._s(_vm._f("formatGreekDate")(item.started_at_date,{ month: '2-digit', year: 'numeric', day: '2-digit' }))+" - "+_vm._s(_vm._f("formatGreekDate")(item.ended_at_date,{ month: '2-digit', year: 'numeric', day: '2-digit' }))+" ")])]}},(_vm.$can('read', 'mentor'))?{key:"cell(rate-mentorship)",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","href":_vm.rateMentorshipUrls.mentorUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('Rate mentorship'))+" ")])]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }